import { useEffect, useRef } from 'react';

const TradingViewWidget = () => {
  const widgetContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js';
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "638",
      currencies: [
        "EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD", "CNY", "TRY", "SEK",
        "NOK", "DKK", "ZAR", "HKD", "SGD", "THB", "MXN", "IDR", "KRW", "PLN", "ISK",
        "KWD", "PHP", "MYR", "INR", "TWD", "SAR", "AED", "RUB", "ILS", "ARS", "CLP",
        "COP", "PEN", "UYU"
      ],
      isTransparent: true,
      colorTheme: "dark",
      locale: "ru",
      backgroundColor: "#1D222D"
    });

    if (widgetContainerRef.current) {
      widgetContainerRef.current.appendChild(script);
    }

    return () => {
      if (widgetContainerRef.current) {
        widgetContainerRef.current.innerHTML = '';
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div ref={widgetContainerRef} className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TradingViewWidget;
