import styles from "./Bonuses.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Gradient from "../../assets/gradient.svg";

export default function Bonuses() {
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.header}>
        <img className={styles.gradient_top} src={Gradient} alt="gradient" />
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.backgroundVideo}
        >
          <source
            src="https://s3-figma-videos-production-sig.figma.com/video/1278271072361010876/TEAM/3d5e/86ac/-56ed-4d8c-88d1-246b74610ebf?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pmX53DnXT9Wror0~cGJFd9qLIYl0VitLT~aRP75LvpFHCRPMek-2jT23I2N7SGM9CDP5uLS~5PMLI79HArxUh9uIz-Gp9ynAxZe6zO9U88KOtxv~ohg72l4EmKrBR6VfLFLPjU8BUW9PpZvmsyzqXVAD3ezSXvPmKpAr7SjNeHKo4rocTg46VsR1hxhgaZlSmXuD7PnT2yhfhX1SvrIFO8J23JLmeHYBIegJ6LbVQO5yzGxA6ddg3J2l7F~Ps1yRqPkzCGv2GOHlAPR-~LadMr22yBWY11-EEFJitdlR6DM237ak5wtLx38VjV2gWpRyf9iBbH-xQvG2SvVFsym4AQ__"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <img className={styles.gradient_bottom} src={Gradient} alt="gradient" />
        <h1>{t("Trade easily and confidently")}</h1>
        <p>{t("Invest with a Unique AI Trade-Bot!")}</p>
        <br />
        <p>
          {t(
            "Absolutely new technology. Our artificial intelligence software analyzes the market and opens trading positions faster and more efficiently than 88% of global traders."
          )}
        </p>
      </div>

      <div className={styles.container}>
        <div className={styles.info_container}>
          <div className={styles.info}>
            <h1>
              {t(
                "Choose the appropriate option and increase your profit without leaving home!"
              )}
            </h1>
            <p>
              {t(
                "See for yourself! We offer new clients a 7-day insurance and the opportunity to test passive earnings with our Trade-Bot."
              )}
            </p>
          </div>
          <div className={styles.blocks}>
            <div className={styles.block}>
              <div className={styles.last_block}>
                <span className={styles.title}>{t("Exchange trading")}</span>
                <div className={styles.blocks__background_orange}>
                  <span className={styles.blocks__orange_info}>
                    {t(
                      "You have access to bonuses, personal advisor services and 0% commission."
                    )}
                  </span>
                </div>
                <ul className={styles.list}>
                  {t("Select an asset:")}
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="1" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("Oil, gas, gold and other raw materials")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="1" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("Shares and indices of world companies")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="1" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("Cryptocurrency")}</span>
                  </li>
                </ul>
              </div>
              <Link to="/" className={styles.button}>
                {t("Sign up")}
              </Link>
            </div>
            <div className={styles.block}>
              <div className={styles.last_block}>
                <span className={styles.title}>{t("Currency exchange")}</span>
                <div className={styles.blocks__background_green}>
                  <span className={styles.blocks__green_info}>
                    {t("We change at the pre-crisis rate!")}
                  </span>
                </div>
                <ul className={styles.list}>
                  {t("Select the amount and find out the rate:")}
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="2" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("From 30,000 to 80,000 $")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="2" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("From $81,000 to $200,000")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="2" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("From $200,000 to $1,000,000")}</span>
                  </li>
                </ul>
              </div>
              <Link to="/" className={styles.button}>
                {t("Sign up")}
              </Link>
            </div>
            <div className={styles.block}>
              <div className={styles.last_block}>
                <span className={styles.title}>{t("Investments")}</span>
                <div className={styles.blocks__background_blue}>
                  <span className={styles.blocks__blue_info}>
                    {t("Rate 5% per month until December 2024")}
                  </span>
                </div>
                <ul className={styles.list}>
                  {t("Select the appropriate option:")}
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="3" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>
                      {t("With the ability to withdraw interest monthly")}
                    </span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="3" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>
                      {t("Without the ability to withdraw interest monthly")}
                    </span>
                  </li>
                </ul>
              </div>
              <Link to="/" className={styles.button}>
                {t("Sign up")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
