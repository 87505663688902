import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store.ts';
import HomePage from './pages/HomePage/HomePage.js';
import AccountTypes from './pages/AccountTypes/AccountTypes.tsx';
import Bonuses from './pages/Bonuses/Bonuses.tsx';
import AffProgram from './pages/AffProgram/AffProgram.tsx';
import AboutCompany from './pages/AboutCompany/AboutCompany.tsx';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.tsx';
import Tools from './pages/Tools/Tools.tsx';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/accounts' element={<AccountTypes />} />
      <Route path='/bonuses' element={<Bonuses />} />
      <Route path='/affiliate' element={<AffProgram />} />
      <Route path='/about' element={<AboutCompany />} />
      <Route path='/privacy' element={<PrivacyPolicy />} />
      <Route path='/tools' element={<Tools/>}></Route>
      <Route path='/tools/:type' element={<Tools/>}></Route>
    </Routes>
  );
}

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default Root;
