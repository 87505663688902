import { useRef, useState, useEffect } from "react";
import styles from "./HomePage.module.scss";
import { useTranslation } from "react-i18next";
import Icon1 from "../../assets/icon1.svg";
import Icon2 from "../../assets/icon2.svg";
import Icon3 from "../../assets/icon3.svg";
import Icon5 from "../../assets/icon5.svg";
import Icon6 from "../../assets/icon6.svg";
import Icon7 from "../../assets/icon7.svg";
import Icon8 from "../../assets/icon8.svg";
import Icon9 from "../../assets/icon9.jpg";
import Arrow from "../../assets/arrow.svg";
import Arrow1 from "../../assets/arrow1.svg";
import Arrow2 from "../../assets/arrow2.svg";
import Gradient from "../../assets/gradient.svg";
import Gradient2 from "../../assets/gradient2.svg";
import Slider from "../../components/Slider.tsx";
import { slides } from "../../components/Slides.tsx";
import Header from '../../components/Header.tsx';
import Footer from '../../components/Footer.tsx';

export default function HomePage() {
  const { t } = useTranslation();
  const sliderRef = useRef<{ slidePrev: () => void; slideNext: () => void } | null>(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSlideChange = (index: number): void => {
    setCurrentSlide(index + 1);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.header}>
      <img className={styles.gradient_top} src={Gradient} alt="gradient" />
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.backgroundVideo}
        >
          <source
            src="https://s3-figma-videos-production-sig.figma.com/video/1278271072361010876/TEAM/3d5e/86ac/-56ed-4d8c-88d1-246b74610ebf?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pmX53DnXT9Wror0~cGJFd9qLIYl0VitLT~aRP75LvpFHCRPMek-2jT23I2N7SGM9CDP5uLS~5PMLI79HArxUh9uIz-Gp9ynAxZe6zO9U88KOtxv~ohg72l4EmKrBR6VfLFLPjU8BUW9PpZvmsyzqXVAD3ezSXvPmKpAr7SjNeHKo4rocTg46VsR1hxhgaZlSmXuD7PnT2yhfhX1SvrIFO8J23JLmeHYBIegJ6LbVQO5yzGxA6ddg3J2l7F~Ps1yRqPkzCGv2GOHlAPR-~LadMr22yBWY11-EEFJitdlR6DM237ak5wtLx38VjV2gWpRyf9iBbH-xQvG2SvVFsym4AQ__"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className={styles.info}>
          <p>{t("Unlock Your Assets Terminal")}</p>
          <h1>{t("Your Path to Financial Success")}</h1>
          <div className={styles.block}>
            <span>
              {t(
                "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading. And a wide range of payment systems helps to perform financial operations quickly and comfortably."
              )}
            </span>
            <div>
              <button>{t("Login")}</button>
              <button>{t("Registration")}</button>
            </div>
          </div>
        </div>
        <img className={styles.gradient_bottom} src={Gradient} alt="gradient" />
      </div>

      <div className={styles.section1}>
        <h2>{t("Trade with a professional")}</h2>
        <div className={styles.blocks}>
          <div className={styles.block}>
            <p>{t("Profitability")}</p>
            <span>
              {t(
                "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading."
              )}
              <br />
              <br />
              {t(
                "And a wide range of payment systems helps to perform financial operations quickly and comfortably."
              )}
            </span>

            <div className={styles.image_container}>
              <img src={Icon1} alt="icon" />
            </div>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
          <div className={styles.block}>
            <p>{t("Technology")}</p>
            <span>
              {t(
                "The company's modern equipment helps traders to trade at the high level accepted in the Forex industry."
              )}
              <br />
              <br />
              {t(
                "Reliable trading platforms, their mobile versions and fast order execution speed increase profitable opportunities."
              )}
            </span>
            <div className={styles.image_container}>
              <img src={Icon2} alt="icon" />
            </div>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
          <div className={styles.block}>
            <p>{t("Services")}</p>
            <span>
              {t(
                "Since 2008, ExchangeUnity has been providing the best services on the market."
              )}
              <br />
              <br />
              {t(
                "Modern trading platforms, personal support, training and a wide range of profitable trading assets."
              )}
            </span>
            <div className={styles.image_container}>
              <img src={Icon3} alt="icon" />
            </div>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div>
          <h2 className={styles.h2}>{t("Your Data. Your Business")}</h2>
          <span className={styles.footer}>
            {t(
              "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading."
            )}
          </span>
        </div>
      </div>

      <div className={styles.section2}>
        <video
          autoPlay
          muted
          loop
          className={styles.video}
          src="https://s3-figma-videos-production-sig.figma.com/video/1278271072361010876/TEAM/5f82/d3f2/-582b-4ea8-b814-c1a14044f06f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LS4Y1AlhXuVwVui0OM8oZFztNUpxXERlYdMmM6Fx6W0L1YGXXjk6DaJBgET0xA-xigqJrt0RI6AdKIYAXTNqt6XHAi5NtVVLfDPzt3oyOybl608cY0ZZ0FthWv5nvkHm5V1xedgPA2RCs0ROllCuBDpeKzga-g45nhIAURYKx6SraGzexBJbGrbwqNpfjcvonkKtN9ph6lgbsRI3sXlOPaRjM~dI8nd6HdSSfCjGNGDRZL5u0V2frCtgTClKR-jzAXQVqXxDR1X0ZqcCCrwxaK7AyAdtwyJ0WrPbOL10FPqnlZ1vJgqFLKgcgHFr6KhHxJh3-9qhuP-Xa~rFueZw0A__"
        ></video>
        <h2>{t("Advantages of Online Platform")}</h2>
        <img className={styles.background} src={Gradient2} alt="gradient" />
        <div className={styles.blocks}>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon5} alt="icon" />
            </div>
            <p>{t("Wide range of instruments")}</p>
            <span>
              {t(
                "Over 170 high-yielding assets, including the most popular stocks and indices, precious metals, energy and cryptocurrencies."
              )}
            </span>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon6} alt="icon" />
            </div>
            <p>{t("Timely risk management")}</p>
            <span>
              {t(
                "Capture profits and limit potential losses in time by setting Stop Loss and Take Profit right on the chart."
              )}
            </span>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon7} alt="icon" />
            </div>
            <p>{t("All operations are supervised")}</p>
            <span>
              {t(
                "The ability to manage open and closed trades, work with limit orders, as well as view the history of all trades for each selected instrument."
              )}
            </span>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon8} alt="icon" />
            </div>
            <p>{t("Best Trading Solutions")}</p>
            <span>
              {t(
                "The Autochartist technical advisor built into the platform opens up even more profitable opportunities."
              )}
            </span>
            <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section3}>
        <img className={styles.background} src={Gradient2} alt="gradient" />
        <h2>{t("Always on the side of the trader")}</h2>
        <div className={styles.blocks}>
          <div>
            <p>14</p>
            <span>{t("Years")}</span>
          </div>
          <div>
            <p>4</p>
            <span>{t("Platforms")}</span>
          </div>
          <div>
            <p>200</p>
            <span>{t("Instruments")}</span>
          </div>
          <div>
            <p>30</p>
            <span>{t("Signals")}</span>
          </div>
          <div>
            <p>320</p>
            <span>{t("Clients")}</span>
          </div>
          <div>
            <p>14</p>
            <span>{t("Years")}</span>
          </div>
        </div>
        <div className={styles.footer}>
          <h2 className={styles.title}>{t("Trusted by Global Companies")}</h2>
          <div className={styles.buttons}>
            {windowWidth > 1300 ? (
              <>
                <span>
                  {currentSlide} / {slides.length}
                </span>
                <button onClick={() => sliderRef.current?.slidePrev()}>
                  <img src={Arrow1} alt="arrow" />
                </button>
                <button onClick={() => sliderRef.current?.slideNext()}>
                  <img src={Arrow2} alt="arrow" />
                </button>
              </>
            ) : (
              <>
                <button onClick={() => sliderRef.current?.slidePrev()}>
                  <img src={Arrow1} alt="arrow" />
                </button>
                <span>
                  {currentSlide} / {slides.length}
                </span>
                <button onClick={() => sliderRef.current?.slideNext()}>
                  <img src={Arrow2} alt="arrow" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.section4}>
        <div className={styles.slider}>
          <Slider ref={sliderRef} onSlideChange={handleSlideChange} />
        </div>
        {windowWidth > 600 ? (
          <>
            <div className={styles.left_side}>
              <h2>{t("Your Path to Financial Success")}</h2>
              <span>
                {t(
                  "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading. And a wide range of payment systems helps to perform financial operations quickly and comfortably."
                )}
              </span>
              <div className={styles.buttons}>
                <button>{t("Request a demo")}</button>
                <button>{t("Contact us")}</button>
              </div>
            </div>
            <div className={styles.right_side}>
              <img src={Icon9} alt="icon" />
              <img
                className={styles.background}
                src={Gradient2}
                alt="gradient"
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.right_side}>
              <img src={Icon9} alt="icon" />
              <img
                className={styles.background}
                src={Gradient2}
                alt="gradient"
              />
            </div>
            <div className={styles.left_side}>
              <h2>{t("Your Path to Financial Success")}</h2>
              <span>
                {t(
                  "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading. And a wide range of payment systems helps to perform financial operations quickly and comfortably."
                )}
              </span>
              <div className={styles.buttons}>
                <button>{t("Request a demo")}</button>
                <button>{t("Contact us")}</button>
              </div>
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}
