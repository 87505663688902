import styles from "./AboutCompany.module.scss";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function AboutCompany() {
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.header}>
        <h1>{t("About Company")}</h1>
        <p>
          {t(
            "Absolutely new technology. Our artificial intelligence software analyzes the market and opens trading positions faster and more efficiently than 88% of global traders."
          )}
        </p>
      </div>
      <div className={styles.info}>
        <div className={styles.info__container}>
          <h1>{t("About ZenithHoldings")}</h1>
          <p>
            {t(
              "ZenithHoldings is an innovative cryptocurrency broker. We provide modern traders and investors with a platform for work that meets all quality criteria."
            )}
          </p>
          <p>
            {t(
              "Cryptocurrency broker ZenithHoldings was founded by an international financial holding company."
            )}
          </p>
          <p>
            {t(
              "Many years of experience help us to create convenient and technological services for working on the global financial markets."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>{t("ZenithHoldings's mission")}</h1>
          <p>
            {t(
              "Due to frequent rate changes, the cryptocurrency market has quickly become a field for traders."
            )}
          </p>
          <p>
            {t(
              "The mission of cryptocurrency broker ZenithHoldings is to become a reliable and modern crypto broker that is accessible to everyone."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>{t("The basis of ZenithHoldings's work")}</h1>
          <p>
            {t(
              "We have developed a functional, intuitive trading platform, which has already become a faithful assistant for beginners and experienced traders."
            )}
          </p>
          <p>
            {t(
              "We simplified the conditions for depositing and withdrawing cryptocurrency, zeroed out monthly fees and commissions for transactions."
            )}
          </p>
          <p>
            {t(
              "We gathered a team of competent and energetic traders who became round-the-clock technical support for our clients."
            )}
          </p>
          <p>
            {t(
              "Ensured server uptime and the highest level of data protection to date with the help of 27 talented technicians."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>{t("ZenithHoldings is open for communication")}</h1>
          <p>
            {t(
              "If you have any questions or queries about cryptocurrency broker ZenithHoldings, just write us here and we will reply to your email."
            )}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
