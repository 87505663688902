import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RootState {
    toolsDrawer: boolean;
  }

export const initialState: RootState = {
    toolsDrawer: false,
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setToolsDrawer: (state, action: PayloadAction<boolean>) => {
      state.toolsDrawer = action.payload;
    },
  },
});

export const { setToolsDrawer } = rootSlice.actions;

export default rootSlice.reducer;
