interface Slide {
  avatar: string;
  text: string;
  name: string;
}

export const slides: Slide[] = [
    {
      name: "Brooklyn Simmons, Director",
      text: "“The Autochartist technical advisor built into the platform opens up even more profitable opportunities.”",
      avatar: "https://s3-alpha-sig.figma.com/img/666a/f9f6/9e832691949526b39b7602dd9f8bab5f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pcPnDDgm0D-8hX6Oy1brZ~Mk4YZTOBFqe~DQvvL-br~SAXw3uyISOQXj5~UcN54mEU2QP~fhTf06ZvAQldToXF5AKviNFOy0LAyEDSZD1FbeeHKdPkRhk0p68pQgWQS2MoezkMOI88fyPD3HXNQ6SKIX56DuwCr~BAXrJMBSyCFJGNHlcXA1WQnj6ONp1qm9WtLPO33gCyCGuwKmX6yZhWnCFPdT69SxvC0Q3LpZD8Ld9VfFDSsbvgPhsYNaQr0H~MzxLWCX6c81Pbde18XMjnduoB8UqNThnGJI6N71K0XqBA0UkYxDPRLsXLxuedUvtN-SzwyJ79KV9-bGgEl5ZQ__",
    },
    {
      name: "Brooklyn Simmons, Director",
      text: "“The Autochartist technical advisor built into the platform opens up even more profitable opportunities.”",
      avatar: "https://s3-alpha-sig.figma.com/img/666a/f9f6/9e832691949526b39b7602dd9f8bab5f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pcPnDDgm0D-8hX6Oy1brZ~Mk4YZTOBFqe~DQvvL-br~SAXw3uyISOQXj5~UcN54mEU2QP~fhTf06ZvAQldToXF5AKviNFOy0LAyEDSZD1FbeeHKdPkRhk0p68pQgWQS2MoezkMOI88fyPD3HXNQ6SKIX56DuwCr~BAXrJMBSyCFJGNHlcXA1WQnj6ONp1qm9WtLPO33gCyCGuwKmX6yZhWnCFPdT69SxvC0Q3LpZD8Ld9VfFDSsbvgPhsYNaQr0H~MzxLWCX6c81Pbde18XMjnduoB8UqNThnGJI6N71K0XqBA0UkYxDPRLsXLxuedUvtN-SzwyJ79KV9-bGgEl5ZQ__",
    },
    {
      name: "Brooklyn Simmons, Director",
      text: "“The Autochartist technical advisor built into the platform opens up even more profitable opportunities.”",
      avatar: "https://s3-alpha-sig.figma.com/img/666a/f9f6/9e832691949526b39b7602dd9f8bab5f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pcPnDDgm0D-8hX6Oy1brZ~Mk4YZTOBFqe~DQvvL-br~SAXw3uyISOQXj5~UcN54mEU2QP~fhTf06ZvAQldToXF5AKviNFOy0LAyEDSZD1FbeeHKdPkRhk0p68pQgWQS2MoezkMOI88fyPD3HXNQ6SKIX56DuwCr~BAXrJMBSyCFJGNHlcXA1WQnj6ONp1qm9WtLPO33gCyCGuwKmX6yZhWnCFPdT69SxvC0Q3LpZD8Ld9VfFDSsbvgPhsYNaQr0H~MzxLWCX6c81Pbde18XMjnduoB8UqNThnGJI6N71K0XqBA0UkYxDPRLsXLxuedUvtN-SzwyJ79KV9-bGgEl5ZQ__",
    },
    {
      name: "Brooklyn Simmons, Director",
      text: "“The Autochartist technical advisor built into the platform opens up even more profitable opportunities.”",
      avatar: "https://s3-alpha-sig.figma.com/img/666a/f9f6/9e832691949526b39b7602dd9f8bab5f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pcPnDDgm0D-8hX6Oy1brZ~Mk4YZTOBFqe~DQvvL-br~SAXw3uyISOQXj5~UcN54mEU2QP~fhTf06ZvAQldToXF5AKviNFOy0LAyEDSZD1FbeeHKdPkRhk0p68pQgWQS2MoezkMOI88fyPD3HXNQ6SKIX56DuwCr~BAXrJMBSyCFJGNHlcXA1WQnj6ONp1qm9WtLPO33gCyCGuwKmX6yZhWnCFPdT69SxvC0Q3LpZD8Ld9VfFDSsbvgPhsYNaQr0H~MzxLWCX6c81Pbde18XMjnduoB8UqNThnGJI6N71K0XqBA0UkYxDPRLsXLxuedUvtN-SzwyJ79KV9-bGgEl5ZQ__",
    },
    {
      name: "Brooklyn Simmons, Director",
      text: "“The Autochartist technical advisor built into the platform opens up even more profitable opportunities.”",
      avatar: "https://s3-alpha-sig.figma.com/img/666a/f9f6/9e832691949526b39b7602dd9f8bab5f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pcPnDDgm0D-8hX6Oy1brZ~Mk4YZTOBFqe~DQvvL-br~SAXw3uyISOQXj5~UcN54mEU2QP~fhTf06ZvAQldToXF5AKviNFOy0LAyEDSZD1FbeeHKdPkRhk0p68pQgWQS2MoezkMOI88fyPD3HXNQ6SKIX56DuwCr~BAXrJMBSyCFJGNHlcXA1WQnj6ONp1qm9WtLPO33gCyCGuwKmX6yZhWnCFPdT69SxvC0Q3LpZD8Ld9VfFDSsbvgPhsYNaQr0H~MzxLWCX6c81Pbde18XMjnduoB8UqNThnGJI6N71K0XqBA0UkYxDPRLsXLxuedUvtN-SzwyJ79KV9-bGgEl5ZQ__",
    },
    {
      name: "Brooklyn Simmons, Director",
      text: "“The Autochartist technical advisor built into the platform opens up even more profitable opportunities.”",
      avatar: "https://s3-alpha-sig.figma.com/img/666a/f9f6/9e832691949526b39b7602dd9f8bab5f?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pcPnDDgm0D-8hX6Oy1brZ~Mk4YZTOBFqe~DQvvL-br~SAXw3uyISOQXj5~UcN54mEU2QP~fhTf06ZvAQldToXF5AKviNFOy0LAyEDSZD1FbeeHKdPkRhk0p68pQgWQS2MoezkMOI88fyPD3HXNQ6SKIX56DuwCr~BAXrJMBSyCFJGNHlcXA1WQnj6ONp1qm9WtLPO33gCyCGuwKmX6yZhWnCFPdT69SxvC0Q3LpZD8Ld9VfFDSsbvgPhsYNaQr0H~MzxLWCX6c81Pbde18XMjnduoB8UqNThnGJI6N71K0XqBA0UkYxDPRLsXLxuedUvtN-SzwyJ79KV9-bGgEl5ZQ__",
    },
  ];